// MAIN COLORS
$GREY: #5d5d5d;
$LIGHT_GREY: #eeeeee;
$ORANGE: #eb6c23;

// OTHER COLORS
$LIGHT_GREY_GRADIENT_TOP: #c1c1c1;
$GREY_GRADIENT_TOP: #979797;
$GREY_GRADIENT_BOTTOM: #6f6f6f;
$LIGHT_GREY_GRADIENT_BOTTOM: #959595;

// CONSTANT SIZES
$navbar-button-width: 110;
$navbar-button-height: 35;
