@import "./utils/styles/constants.scss";

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.694);
  border-radius: 10px;
&:hover {
  background: rgba(128, 128, 128, 0.494);
  }
}

html {
  overflow-y: scroll;
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

hr {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.35);
}

.App {
  height: 100%;
  color: $GREY;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}

.MuiPaper-root {
  padding: 20px;
  color: $GREY !important;
}

.leaflet-container {
  height: 300px;
  width: 100%;
}

.fade {
  animation: fadeAnimation 0.8s forwards;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 45px;
  width: 220px;
}

.MuiFormControl-root.MuiTextField-root {
  margin-bottom: 15px;
}

.MuiAlert-root {
  color: white !important;
}

.MuiInputLabel-formControl.MuiInputLabel-animated {
  color: $GREY;
}
.MuiOutlinedInput-root {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.42);
  }
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
