@import "../../utils/styles/constants.scss";

.navbar {
  font-size: 20px;

  &-brand {
    display: flex;
    justify-content: center;
  }

  &-flex-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    color: $GREY;
  }

  &-nav {
    width: 100%;
    justify-content: space-around;
    .nav-item {
      .nav-link {
        color: $GREY;
        &:hover {
          color: #000;
        }
        &.active {
          font-weight: 700;
          color: #000;
        }
        .navbar-button {
          border-radius: 3px;
          padding: 0;
          color: #fff;
          font-size: 16px;
          width: $navbar-button-width * 1px;
          height: $navbar-button-height * 1px;
          &.active {
            font-weight: 700;
            color: #000;
          }
          &:hover {
            color: #000;
            background-image: linear-gradient(
              $LIGHT_GREY_GRADIENT_TOP,
              $GREY_GRADIENT_TOP
            );
          }
          &::before {
            content: "Contact";
            position: absolute;
            transform: translate(-50%, 2px);
          }

          .navbar-button-gradient-top {
            height: 50%;
            background-image: linear-gradient(
              $LIGHT_GREY_GRADIENT_TOP,
              $GREY_GRADIENT_TOP
            );
          }

          .navbar-button-gradient-bottom {
            height: 50%;
            background-image: linear-gradient(
              $GREY_GRADIENT_BOTTOM,
              $LIGHT_GREY_GRADIENT_BOTTOM
            );
          }
        }
      }
    }
  }

  @media (max-width: 991.5px) {
    &-flex-container {
      justify-content: space-between;
    }

    &-img {
      display: none;
    }

    &-nav {
      border-right: 1px solid $GREY;
      border-left: 1px solid $GREY;
      border-radius: 3px;
      .nav-item {
        border: 1px solid $LIGHT_GREY;
        &:first-child {
          border-top: 1px solid $GREY;
        }
        &:last-child {
          border-bottom: 1px solid $GREY;
        }
        &:hover {
          border: 1px solid $GREY;
        }
        &.active {
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}
