@import "../../utils/styles/constants.scss";

.logo-container {
  display: flex;
  flex-direction: column;

  .logo-content-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateY(-5px);

    .logo-text {
      color: $GREY;
      font-size: 20px;
    }
  }
}

@media (max-width: 991.5px) {
  .logo-container {
    .logo-content-container {
      .logo-text {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 379px) {
  .logo-container {
    .logo-content-container {
      .logo-text {
        font-size: 12px;
      }
    }
  }
}
